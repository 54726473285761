import Draggable from "react-draggable";
import React, {useEffect, useState} from "react";
import './draggableWidget.scss'
import {useDispatch} from "react-redux";
import {BuySellServices, ceService} from "../../sub";
import {BuySellWIndowInstance} from "../ChartBuySell";
import {NumberColor} from "../common/number_color";


function DraggableWidget(props) {

    const dispatch = useDispatch();
    const [ modelOpen, setModelOpen ] = useState(false)
    const [ buySellData, setBuySellData ] = useState(null)

    const handleEvent = (e, data) => {
        // console.log('Event Type', e.type);
        // console.log(e, data);
    }

    const click_me = (e, data) => {

    }


   let subscription = undefined
    useEffect(() => {
        subscription = BuySellServices.getSub().subscribe(value => {
            setModelOpen(value.model_open)
            setBuySellData(value.data)
        });
        return () => {
            subscription?.unsubscribe();
        };
    }, []);


    useEffect(() => {

     if(buySellData) BuySellWIndowInstance.ShowOrderDialog({
            symbol: buySellData.buySellSymbol,
            limitPrice: buySellData.lp,
            side: buySellData.type === "B" ? 1 : -1
        })

    }, [buySellData]);
    
    useEffect(() => { 
        return () => {
            BuySellServices.clearBuySell();
        }
    }, [])




    function onModelClose() {
        BuySellServices.clearBuySell();
    }

    if(modelOpen === false){
        return null
    }

    return null;

}




export default DraggableWidget;
