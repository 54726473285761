import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './addTobasket.css'
import Form from 'react-bootstrap/Form';
import TopHeader from "./topHead";
import {useSelector} from "react-redux";
import {currentUser} from "../../../slices/authSlice";
import flowBackEndService from "../../../services/flowBackendApi";
import {toast} from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import {basketSubServices} from "../basketSub";
import {BrokerApiInstance} from "../../../chart-brokerapi/broker";
import {DataFeedInstance} from "../../../chart-datafeed/udf-compatible-datafeed";
import {logMessage} from "../../../utils/helpers";

const BasketData = ({close,stockData,modification}) => {

     // console.log("BASSS",stockData,modification)

    const [ productType, SetProductType ] = useState('INTRADAY')
    const [ validity, setValidity ] = useState('DAY')
    const [ stopLoss, setStopLoss ] = useState(false)
    const [ limitMarket, setLimitMarket ] = useState(false)
    const [ buySell, setBuySell ] = useState(true);
    const [ IsAmo, setIsAmo ] = useState(false);
    const [ Type, setType ] = useState(stockData?.type);

    const [ LiveData, setLiveData ] = useState(null)
    const { basketDetails } = useSelector((state) => state.basketOrder);
    const { listDataMain } = useSelector((state) => state.basketOrder);
    const [isLoading, setIsLoading] = useState(false);
    const [isMarginLoading, setIsMarginLoading] = useState(false);

    const [marginData, setMarginData] = useState(null);
    const [symbolInfoData, setSymbolInfoData] = useState(null);
    const [fullState, setFullState] = useState({symbol: stockData.symbol,side:1,qty: 1, oldQty: 0,oldPrice: 0,market_segment_id: '',token: '',lot_size: 1,limitPrice: 0,priceScale: 1,market_type: 1,market: 1,mode:'N',order_id:'',margin:'',validity:'',tick_size:0});


    const user = useSelector(currentUser);
    const handleBuySell = (value) => setBuySell(value);

    const [marginx, setMarginx] = useState({INTRADAY: 5,PAYLATER:0,DELIVERY:1})
    const [calMargin, setcalMargin] = useState({required: 0})


    const [initialMargin, setInitialMargin] = useState({INTRADAY: null,PAYLATER:null,DELIVERY:null})
    const [ firstDataCome, setFirstDataCome ] = useState(false);




    function getInitData() {
        if(modification === 'M'){
            let mydata =   stockData.currentData;
            if(mydata.triggerPrice > 0) setTimeout(setTrigerPricePercenatge,500)
            return {lot: mydata.quantity,lotPrice: mydata.price,triger_price: mydata.triggerPrice,triger_price_percentage: '0'};
        }
        return {lot: '1',lotPrice:'',triger_price:'',triger_price_percentage: '0.0'};
    }

    const [formState, setFormState] = useState(getInitData);
    const [ errors, setErrors ] = useState({})

    const productTypeModel = {INTRADAY: '1',PAYLATER:'2',DELIVERY:'3'}
    const ValidtyTypeModel = {DAY: '1',IOC:'2',GTT:'3'}
    const getOrderTypeModel = () => {
        if(stopLoss){
           return limitMarket ?  {order_type: 'SL',order_value: '3',market_type:'1'} : {order_type: 'SL-MKT',order_value: '4',market_type:'2'}
        }else {
            return limitMarket ?  {order_type: 'RL',order_value: '1',market_type:'1'} : {order_type: 'RL-MKT',order_value: '2',market_type: '2'}
        }
    }

    function updateFullstateOnBuySellProductChnage() {
        setFullState({...fullState,side: buySell ? 1: -1,margin: productTypeModel[productType],priceScale: (symbolInfoData?symbolInfoData.pricescale:fullState.priceScale),validity:ValidtyTypeModel[validity]})
    }

    // function setsegmentAndToken() {
    //     setFullState({...fullState,market_segment_id: LiveData.market_segment_id,token:LiveData.token,limitPrice: LiveData.lp  })
    // }

    function updateOrderType() {
       let mdata = getOrderTypeModel()
       if(mdata) setFullState({...fullState,market: parseInt(mdata.market_type)})
    }

    useEffect(() => {
        resolveSymbol()
        updateFullstateOnBuySellProductChnage()
    },[])

    useEffect(() => {
        if(validity === 'GTT' && !buySell) setValidity('DAY')
        if(validity === 'GTT' && productType !== 'DELIVERY') setValidity('DAY')
        updateFullstateOnBuySellProductChnage()
    },[buySell,productType,validity])

    function setModificationData() {
         let mydata =   stockData.currentData;
         setBuySell(mydata.buySell === 1)
         setFormState({...formState, lot: mydata.quantity,lotPrice: mydata.price,triger_price: mydata.triggerPrice})
         setIsAmo(mydata.isAmo)

        for (const prop in productTypeModel) {
           if (productTypeModel[prop] === mydata.productType) SetProductType(prop)
        }

        for (const prop in ValidtyTypeModel) {
            if (ValidtyTypeModel[prop] === mydata.validity) setValidity(prop)
        }

        if(mydata.orderType === '1' || mydata.orderType === '2'){
            setStopLoss(false)
            setLimitMarket(mydata.orderType === '1')
        }else if(mydata.orderType === '3' || mydata.orderType === '4'){
            setStopLoss(true)
            setLimitMarket(mydata.orderType === '3')
        }


    }

    useEffect(() => {
     if(modification === 'M')  setModificationData()
    },[modification])


     useEffect(() => {
       if(initialMargin.INTRADAY && initialMargin.DELIVERY) getAllMaringX()
     },[fullState.side])

    // useEffect(() => {
    //     setFullState({...fullState,qty: (formState.lot) * (fullState.market_segment_id === '2' ? fullState.lot_size : 1)})
    // },[formState.lot,formState.lotPrice])

    useEffect(() => {
        setTrigerPricePercenatge()
    },[formState.triger_price])

   function setTrigerPricePercenatge(){
        if(formState.triger_price && LiveData){
            let val = (+formState.triger_price / +LiveData.lp) * 100
            setFormState({...formState,triger_price_percentage: val?.toFixed(2)})
        }else if(formState.triger_price === '') {
            setFormState({...formState,triger_price_percentage: '0.0'})
        }
    }

    useEffect(() => {
        updateOrderType()
    },[limitMarket,stopLoss])

    useEffect(() => {
       if (symbolInfoData) {
           setFullState({...fullState,priceScale: symbolInfoData.pricescale,lot_size: symbolInfoData.odin_lot_size, tick_size:  (parseFloat(symbolInfoData.minmov)/parseFloat(symbolInfoData.pricescale))})
           // setFirstDataCome(true)
       }
    },[symbolInfoData])

    useEffect(() => {
        if(validity === 'GTT') handelLimitMarket(true)
        updateFullstateOnBuySellProductChnage()
    },[validity])


    useEffect(() => {
        if(LiveData && firstDataCome === false) OnFirstLTPCome()
    },[LiveData])

    useEffect(() => {
        if(firstDataCome && symbolInfoData && fullState.limitPrice !== 0) getAllMaringX()
    },[firstDataCome,fullState.limitPrice,fullState.priceScale])

    function OnFirstLTPCome() {
        logMessage(`BasketData.js: First time ltp received`)
        setFormState({...formState, lotPrice: String(LiveData.lp)})
        setFullState({...fullState,market_segment_id: LiveData.market_segment_id,token:LiveData.token,limitPrice: LiveData.lp })
        setFirstDataCome(true)
    }

    function getAllMaringX() {
        let dataM = marginCalculate();
        let intradata = {...dataM,margin: '1'};
        let payLater = {...dataM,margin: '2'};
        let delivery = {...dataM,margin: '3'};

        const p1 = new Promise((resolve, reject) => {
            BrokerApiInstance.getRequiredMargin(intradata).then((response)=>{
                resolve(response);
            })
        });

        const p2 = new Promise((resolve, reject) => {
            if(fullState.market_segment_id === '1'){
                BrokerApiInstance.getRequiredMargin(payLater).then((response)=>{
                    resolve(response);
                })
            }else {
                resolve({done: 'NO API CALL'});
            }
        });

        const p3 = new Promise((resolve, reject) => {
            BrokerApiInstance.getRequiredMargin(delivery).then((response)=>{
                resolve(response);
            })
        });

        Promise.all([p1, p2, p3]).then((values) => {
            let aa = {INTRADAY: values[0],PAYLATER: values[1],DELIVERY: values[2]}
            setInitialMargin(aa)
        });
    }

    function updateMarginData(){
       let currvalue = initialMargin[productType]
        if(currvalue){
         let val =   (+currvalue.required_margin * (+formState.lot) * +formState.lotPrice) / fullState.limitPrice
            setMarginData({...currvalue,required_margin: val.toFixed(2)})
        }
    }


    useEffect(() => {
     if(initialMargin.INTRADAY) setMagingXValues()
    },[initialMargin])

    useEffect(() => {
        if(initialMargin.INTRADAY) updateMarginData()
    },[productType])

    useEffect(() => {
        if(initialMargin.INTRADAY) updateMarginData()
    },[formState.lot,formState.lotPrice])



    function setMagingXValues() {
        // console.log("setMagingXValues");
        let intradatayx = marginx.INTRADAY;
        let paylaterx = marginx.PAYLATER;
        let deliveryx = marginx.DELIVERY

        if(initialMargin.INTRADAY){
            let val = fullState.limitPrice / (initialMargin.INTRADAY.required_margin)
            if(fullState.market_segment_id !== '1'){
              val =  (fullState.limitPrice * fullState.lot_size) / (initialMargin.INTRADAY.required_margin)
              if(fullState.symbol.toString().includes("FUT")) val = 1.0
            }
            intradatayx = +Number(val).toFixed(1)
        }
        if(initialMargin.PAYLATER){
            let val = fullState.limitPrice / (initialMargin.PAYLATER.required_margin)
            if(fullState.market_segment_id !== '1'){
                val =  (fullState.limitPrice * fullState.lot_size) / (initialMargin.PAYLATER.required_margin)
            }else if(!buySell) {
                val =  1.0
            }
            paylaterx = +Number(val).toFixed(1)
        }
        if(initialMargin.DELIVERY){
            let val = fullState.limitPrice / (initialMargin.DELIVERY.required_margin)
            if(fullState.market_segment_id !== '1'){
                val =  1.0
            }else if(!buySell) {
                val =  1.0
            }
            deliveryx = +Number(val).toFixed(1)
        }
         setMarginx({INTRADAY: intradatayx,PAYLATER: paylaterx,DELIVERY: deliveryx})
        updateMarginData()
    }

    function handleFormStateChange(event) {
        let { name, value } = event.target;

        if(name === 'lotPrice'){
            value = decimalValue(value)
        }

        setFormState({ ...formState, [name]: value });

        if ( !!errors[name] ) setErrors({
            ...errors,
            [name]: null
        })
    }

    function decimalValue(value) {
        let a = value.toString().split('.')
        if (LiveData.market_segment_id === '13' && a.length > 1 && a[1].length > 4) {
            value = value.toString().slice(0, (value.indexOf("."))+5);
        }else if(LiveData.market_segment_id !== '13'){
                if (a.length > 1 && a[1].length > 2) {
                    value = value.toString().slice(0, (value.indexOf("."))+3);
                } else if (a.length > 3) {
                    value = value.toString().slice(0, (value.indexOf("."))+3);
                }
            }

       return  value.toString()
    }

    function marginCalculate() {
        return {
            symbol: fullState.symbol,
            side: fullState.side,
            qty: (fullState.qty) * (fullState.market_segment_id === '2' || fullState.market_segment_id === '4' ? fullState.lot_size : 1),
            oldQty: (fullState.oldQty) * (fullState.market_segment_id === '2' || fullState.market_segment_id === '4' ? fullState.lot_size : 1),
            oldPrice: fullState.oldPrice,
            id: fullState.order_id,
            validity: fullState.validity,
            limitPrice: fullState.limitPrice,
            margin: fullState.margin,
            mode: fullState.mode,
            market: (fullState.market_type === 1 ? 0 : 1),
            lot_size: fullState.lot_size,
            priceScale: fullState.priceScale
        };
    }

   // function updateMargin(){
   //      let dataM = marginCalculate();
   //      // console.log("MARGIN2",dataM)
   //      setIsMarginLoading(true)
   //      BrokerApiInstance.getRequiredMargin(dataM).then((response)=>{
   //          // console.log("MARGIN RES2",response)
   //          setIsMarginLoading(false)
   //          setMarginData(response?.data?.result)
   //      })
   //  }





    function resolveSymbol() {
        DataFeedInstance.resolveSymbol(stockData.symbol, (symbolInfo)=>{
            // var instrumentType = segmentToInstrumentType(symbolInfo.odin_exchange)
            // var scrip_name = symbolInfo.odin_symbol
            setSymbolInfoData(symbolInfo);
        }, undefined, undefined)

    }


    const setSubData = (data) => setLiveData(data);


    const handleChange = (e) => {
       setStopLoss(e.target.checked)
    }

    function handelOderType(Type) {
            SetProductType(Type);
    }

    function handelValidty(validityType) {
        setValidity(validityType)
    }

    function handelLimitMarket(bool) {
        setLimitMarket(bool)
        setFormState({...formState, lotPrice: String(LiveData.lp)})
    }

    function getGTTBox() {
        if(Type === 'EQ'&& buySell && productType === 'DELIVERY'){
          return  <div   onClick={() => handelValidty('GTT')} className={`validity_box ml-3 ${ validity === 'GTT' ? 'active_v_box':''}`}>GTT</div>;
        }else if(getPOA() && !buySell) {
            return  <div   onClick={() => handelValidty('GTT')} className={`validity_box ml-3 ${ validity === 'GTT' ? 'active_v_box':''}`}>GTT</div>;
        }else {
           return null;
        }
    }

    function getPOA() {
      const ispoa =  user.others.POA;
      return ispoa === 0;
    }

    const isBetween = (num1,num2,value) => value > num1 && value < num2

    const onlyNumber = (event) => {
            if (!/[0-9.]/.test(event.key)) {
                event.preventDefault();
            }
    }



    const findFormErrors = () => {
        const lot = Number(formState.lot)
        const lotPrice = Number(formState.lotPrice)
        const triger_price  = Number(formState.triger_price)
        const newErrors = {}


        if ( lot === 0 ) newErrors.lot = 'Enter valid lot'
        if ( lotPrice === 0 ) newErrors.lotPrice = 'Price is required!'

        // if(LiveData?.dpr){
        //    let val = LiveData.dpr.split("-")
        //     const min = Number(val[0])
        //     const max = Number(val[1])
        //    if (!isBetween(min,max,lotPrice)) newErrors.lotPrice = 'Price is Not in range Dpr'
        // }

        if(lotPrice > 0 && fullState.tick_size && limitMarket){
           if (!tickValidation(lotPrice,fullState.tick_size)) newErrors.lotPrice = 'Price is not multiple of Tick!'
        }

        if(stopLoss && triger_price === 0) newErrors.triger_price = 'Trigger price should provide when stop loss on'

        if(limitMarket && stopLoss){
            if (buySell){
                if(!(lotPrice >= triger_price)) newErrors.triger_price = 'Trigger price should be less than or equal to Limit price'
            }
            if (!buySell){
                if(!(triger_price >= lotPrice)) newErrors.triger_price = 'Trigger price should be greater than or equal to Limit price'
            }
        }

        // if((stopLoss === false) && (triger_price !== 0)) newErrors.stop_loss = 'Trigger price should be Zero when stop loss off'


        return newErrors
    }

    function tickValidation(lotPrice,tick) {

        let res = false

        if (LiveData.market_segment_id === '13') {
            let pricess = Math.round(lotPrice*10000)
            let tickss = (tick * 10000)
            let val = pricess % tickss
            val = parseInt(val,0)
            res = val === 0
        }else{

            let pricess = Math.round(lotPrice*100)
            let tickss = (tick * 100)
            let val =  pricess % tickss
            val = parseInt(val,0)
            res = val === 0
        }

        return res
    }




    function callApiUpdateBasketOrder(datas) {
        const oldorder = listDataMain.find(d => d.id === basketDetails.id).basketOrders
        // const oldorder = basketDetails.basketOrders;
        const orderlist = [...oldorder,datas];
        setIsLoading(true)
        const data = {basketName: basketDetails.basketName,orders:orderlist}
        flowBackEndService.UpdateBasket(data,user.access_token,basketDetails.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    basketSubServices.setNewBasket(orderlist)
                    close();
                }else{
                    BrokerApiInstance._host.showNotification("Error during basket updation",value?.data?.message ,0)
                }
            })
            .catch(error => {
                BrokerApiInstance._host.showNotification("Error during basket updation",error.response.data.message ,0)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })

    }

    function updateAndReturnObject(oldData,newData){
        return {...oldData,buySell: newData.buySell,
                           productType: newData.productType,
                           orderType: newData.orderType,
                           quantity: newData.quantity,
                           price: newData.price,
                           disclosedQuantity: newData.disclosedQuantity,
                           validity: newData.validity,
                           orderId:"",
                           isAmo: newData.isAmo,
                           triggerPrice: newData.triggerPrice,
        }
    }


    function callApiModifyBasket(datas) {
        const oldorder = listDataMain.find(d => d.id === basketDetails.id).basketOrders
        let mydata =  stockData.currentData;
        let currOrder =  oldorder.find(d => d.id === mydata.id)
        const updatedData = updateAndReturnObject(currOrder,datas)
        let orderlist = [...oldorder];
        orderlist = orderlist.map(u => u.id !== mydata.id ? u : updatedData);
        setIsLoading(true)
        const data = {basketName: basketDetails.basketName,orders:orderlist}
        flowBackEndService.UpdateBasket(data,user.access_token,basketDetails.id)
            .then(value => {
                if(value.data.status ===  'success'){
                    basketSubServices.setNewBasket(orderlist)
                    close();
                }else{
                    BrokerApiInstance._host.showNotification("Error during updating basket",value?.data?.message ,0)
                }
            })
            .catch(error => {
                BrokerApiInstance._host.showNotification("Error during updating basket",error.response.data.message ,0)
                setIsLoading(false);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    function checkAuthoriseRequired(curData) {
        if(getPOA()){
            return {value: false,message:''}
        }
        if (curData.buySell === -1){
            if(symbolInfoData.odin_exchange === 'NSE_EQ' && (curData.productType === '2' || curData.productType === '3') ){
                // POA is required to place Equity Delivery & Pay Later Delivery Sell orders in basket order
                 return {value: true,message:'POA is required to place Equity Delivery & Pay Later Delivery Sell orders in basket order'}
            }else if(curData.validity === '3'){
                // To place Sell GTD orders, please complete Power of Attorney(POA)
                return {value: true,message:'To place Sell GTD orders, please complete Power of Attorney(POA)'}
            }else {
                return {value: false,message:''}
            }
        }else {
            return {value: false,message:''}
        }
    }

    function handleAddbasket(event) {
        event.preventDefault();
        // get our new errors
        const newErrors = findFormErrors()
        // Conditional logic:
        if ( Object.keys(newErrors).length > 0 ) {
            setErrors(newErrors)
        } else {
            const data = getJsonOrder();
            let edis = checkAuthoriseRequired(data);
            if(edis.value){
                toast.info(`${edis.message}`);
                return
            }
            if(symbolInfoData.asmGsmStage){
                const dialogBox = document.querySelector(".custom-order-dialog_basket")
                close();
                dialogBox.style.zIndex = '-1';
                window.tvWidget.showConfirmDialog({title: "Disclaimer",body:`Security is under Surveillance Measure- ${symbolInfoData.asmGsmStage}. Do you wish you continue?`,callback: (confirmed)=>{
                    dialogBox.style.zIndex = '41';
                    if(confirmed) modification === 'M' ? callApiModifyBasket(data): callApiUpdateBasketOrder(data)
                }})
            }else{
                modification === 'M' ? callApiModifyBasket(data): callApiUpdateBasketOrder(data)
            }
        }
    }

    function getJsonOrder(){
        return {
            token: Number(LiveData.token),
            marketSegmentId: Number(LiveData.market_segment_id),
            buySell: buySell ? 1 : -1,
            productType: productTypeModel[productType],
            orderType: getOrderTypeModel().order_value,
            quantity: Number(formState.lot),
            price:  Number(formState.lotPrice),
            disclosedQuantity: 0,
            triggerPrice: stopLoss ? Number(formState.triger_price) : 0,
            validity: ValidtyTypeModel[validity],
            isAmo: IsAmo,
        };
    }

    function limitMarktBox() {

        if(Type === 'EQ'&& validity === 'GTT'){
            return (
                    <div  onClick={() => handelLimitMarket(true)} className={`only_limitBox`} >
                        <div className="align-self-center">Limit</div>
                    </div>
            )
        }else {
            return(
                <div className="limit_market">
                    <div  onClick={() => handelLimitMarket(true)} className={`flex-fill h-100 d-flex flex-row justify-content-center align-items-center ${ limitMarket ? 'bg_limit':''}`} >
                        <div className="align-self-center">Limit</div>
                    </div>
                    <div   onClick={() => handelLimitMarket(false)} className={`flex-fill h-100 d-flex flex-row justify-content-center align-items-center ${ limitMarket ? '':'bg_market'}`}>
                        <div className="p-2 pr-3">Market</div>
                    </div>
                </div>
            )
        }

    }

    return (
       <React.Fragment>


            <TopHeader subDatafun={setSubData} data={stockData} close={close} handleBuySell={handleBuySell} buySell={buySell} />

            <div className="d-flex flex-row justify-content-between header-basket2 px-4">

                <div onClick={() => handelOderType('INTRADAY')} className={`d-flex flex-fill w-50  flex-column justify-content-start ${ productType === 'INTRADAY' ? 'active_border':''}`}>
                    <div className="d-flex flex-row justify-content-start">
                        <div className="second_heading small_m currentText">Intraday</div>
                        <div id="badge_exch" className="">{marginx['INTRADAY']}X</div>
                    </div>
                    {/*<div className="heading_light_thin">Max Qty 72</div>*/}
                </div>

                { (stockData?._source?.MktSegId.toString() === '1') && (
                    <div onClick={() => handelOderType('PAYLATER')} className={`d-flex flex-fill w-50 flex-column justify-content-start ${ productType === 'PAYLATER' ? 'active_border':''}`}>
                        <div className="d-flex flex-row justify-content-start">
                            <div className="second_heading small_m currentText">Pay Later</div>
                            <div id="badge_exch" className="">{marginx['PAYLATER']}X</div>
                        </div>
                        {/*<div className="heading_light_thin">Max Qty 72</div>*/}
                    </div>
                )}

                <div onClick={() => handelOderType('DELIVERY')} className={`d-flex flex-fill w-50 flex-column justify-content-start ${ productType === 'DELIVERY' ? 'active_border':''}`}>
                    <div className="d-flex flex-row justify-content-start">
                        <div className="second_heading small_m currentText"> {(stockData?._source?.MktSegId.toString() === '1') ? 'Delivery' : 'CarryForward'} </div>
                        <div id="badge_exch" className="">{marginx['DELIVERY']}X</div>
                    </div>
                    {/*<div className="heading_light_thin ">Max Qty 72</div>*/}
                </div>
            </div>


            <div className="scroll_box my_scroll_track">
               <Form autoComplete="off">
                 <div className="d-flex flex-row justify-content-between align-items-start px-4 py-1">
                   <div className="flex-fill w-50">
                       <Form.Group className="" controlId="formBasicEmail">
                           <Form.Label className="text1 w-100">
                               <div className="d-flex flex-row justify-content-between align-items-center">
                                   <div className="heading2">Enter Lots</div>
                                   <div className="">&nbsp;</div>
                               </div>
                           </Form.Label>
                           <Form.Control type="text"
                                         name="lot"
                                         onKeyPress={onlyNumber}
                                         isInvalid={ !!errors.lot }
                                         value={formState.lot}
                                         onChange={handleFormStateChange}
                                         className="padding15" placeholder="Enter Lot" />
                           <Form.Control.Feedback type='invalid'>
                               { errors.lot }
                           </Form.Control.Feedback>
                           <Form.Text className="help_text currentText">
                               <div className="pt-1">1 lot = {fullState.lot_size}</div>
                           </Form.Text>

                       </Form.Group>
                   </div>
                   <div className="px-2"/>
                   <div className="flex-fill w-50">
                       <Form.Group className="" controlId="formBasicEmail">
                           <Form.Label className="text1 w-100">
                               <div className="d-flex flex-row justify-content-between align-items-center">
                                   <div className="heading2">Price</div>
                                   <div className="colorGrey currentText">Tick: {fullState.tick_size}</div>
                               </div>
                           </Form.Label>
                           <Form.Control type="text" className="padding15 " name="lotPrice"
                                         onKeyPress={onlyNumber}
                                         isInvalid={ !!errors.lotPrice }
                                         value={ limitMarket ? formState.lotPrice : 'Market Price'}
                                         onChange={handleFormStateChange}
                                         disabled={!limitMarket} placeholder="Enter Price" />
                           <Form.Text className="help_text pt-1 d-flex flex-column justify-content-start align-items-end">
                               <div className="">DPR: {LiveData?.dpr}</div>
                               { buySell ?
                                   <div className="">Top Ask: {LiveData?.ask} | Qty: {LiveData?.ask_qty}</div>
                                   : <div className="">Top Bid: {LiveData?.bid} | Qty: {LiveData?.bid_qty}</div>
                               }

                           </Form.Text>
                           <Form.Control.Feedback type='invalid'>
                               { errors.lotPrice }
                           </Form.Control.Feedback>
                       </Form.Group>
                   </div>
               </div>

                 <div className="d-flex flex-row justify-content-between align-items-center px-4 py-1">
                        <div className="heading2">Stop Loss</div>
                        <div className="">
                            <div className="field custom-order-dialog__sl-switch-row">
                                <input type="checkbox" name="sl-switch" id="sl-switch2" checked={stopLoss} onChange={handleChange}  className="is-large switch is-rtl is-rounded "/>
                                <label htmlFor="sl-switch2"/>
                            </div>
                        </div>
                     {limitMarktBox()}
                   </div>

                   {stopLoss && (
                       <div  className="d-flex flex-row justify-content-between  px-4 py-1">
                           <div className="flex-fill w-100">
                               <Form.Group className="" controlId="formBasicEmail">
                                   <Form.Label className="text1 w-100">
                                       <div className="d-flex flex-row justify-content-between align-items-center">
                                           <div className="heading2">Trigger Price</div>
                                           <div className="colorGrey">{formState.triger_price_percentage}% of LTP</div>
                                       </div>
                                   </Form.Label>
                                   <Form.Control type="text"
                                                 name="triger_price"
                                                 onKeyPress={onlyNumber}
                                                 isInvalid={ !!errors.triger_price }
                                                 value={formState.triger_price}
                                                 onChange={handleFormStateChange}
                                                 className="py-3" placeholder="Enter Trigger Price" />
                                   <Form.Control.Feedback type='invalid'>
                                       { errors.triger_price }
                                   </Form.Control.Feedback>
                               </Form.Group>
                           </div>
                       </div>
                   )}



                   <div className="px-4 py-1">
                       <div className="heading2">Validity</div>
                       <div className="d-flex flex-row justify-content-start">
                           <div  onClick={() => handelValidty('DAY')} className={`validity_box ${ validity === 'DAY' ? 'active_v_box':''}`}  >DAY</div>
                           <div   onClick={() => handelValidty('IOC')} className={`validity_box ml-3 ${ validity === 'IOC' ? 'active_v_box':''}`}>IOC</div>
                           {/* {getGTTBox()} */}
                       </div>
                   </div>


                   <div className="px-4 py-1 d-flex flex-row justify-content-between align-items-center">
                       <div className="heading2">After Market Order</div>
                       <div className="">
                           <div className="field custom-order-dialog__sl-switch-row">
                               <input type="checkbox" name="sl-switch3" id="sl-switch3" checked={IsAmo} onChange={() => setIsAmo(!IsAmo)}  className="is-large switch is-rtl is-rounded "/>
                               <label htmlFor="sl-switch3"/>
                           </div>
                       </div>

                   </div>

               </Form>
           </div>

           { marginData && (
               <div className="required_margin currentText card_bg px-4 py-2">
                   <div className="d-flex flex-row justify-content-between align-items-center">
                       <div className="d-flex flex-column justify-content-evenly align-items-start">
                           <div className="text_grey currentText">Required</div>
                           <div className="heading2">{marginData.required_margin ?? 0.00}</div>
                       </div>
                       <div className="d-flex flex-column justify-content-evenly align-items-end">
                           <div className="text_grey currentText">Available</div>
                           <div className="heading2">{marginData.available_margin ?? 0.00}</div>
                       </div>
                   </div>
               </div>
           )}





           <div onClick={handleAddbasket} className="add_basket_btn c-pointer">
               {isLoading ? (
                   <Spinner className="mr-2" as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
               ): <div> {modification === 'M'? 'Modify':'+Add'} to basket</div>}
           </div>

       </React.Fragment>

    );
};

export default BasketData;

