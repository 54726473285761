import React, {useEffect, useRef, useState} from 'react';
import BasketketHeader from './Header/bacsketHeader';
import {useNavigate } from 'react-router-dom';
import Draggable from 'react-draggable';
import PublicEachStock from "./PublicEachStock";
import nextId from "react-id-generator";
import { ExecutePublicBasket,sharedBasketAnalyse,sharedBasketSave,setCurrentBasketState,setPublicBasketData,setBasketOldIdData,setPublicBasketMargin, OpenPublicBasket} from "../../slices/basketOrderSlice";
import {shallowEqual,useDispatch,useSelector} from "react-redux";
import { ReactComponent as Save } from "../../assets/icons/symbols_save.svg";
import Button from "react-bootstrap/Button";
import flowBackEndServices2 from "../../services/flowBackendServices";
import flowBackEndService from "../../services/flowBackendApi";
import {toast} from "react-toastify";
import CustomSpinner from '../OptionStrategy/CustomSpinner';
import {ReactComponent as InfoCircle} from "../../assets/icons/info-circle2.svg";
import moment from 'moment';


const SharedBasketDetails = ()=>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [apiDetail,setApiDetail]=useState(null)
    const [ totalMargin, setTotalMargin ] = useState(null)
    const [savedBasketId,setSavedBasketId]=useState(null)
    const [isLoading,setIsLoading]=useState(true)
    const user = useSelector((state => state.authUser.user),shallowEqual);

    useEffect(()=>{
        let basket_id=sessionStorage.getItem("public_basket_id")
        if( basket_id && basket_id!="") fetchData(basket_id)
    },[])

    useEffect(()=>{
      if(apiDetail) callTotalMarginApi(apiDetail.basketDetails.basketOrders)
    },[apiDetail])

    const handleAppendParams = () => {
        const queryParams = new URLSearchParams();
        queryParams.set('basket_id', basket_id);
        navigate(`?${queryParams.toString()}`);
      };

      function formateMarginDara(data) {
        return  {
             "token": data.token,
             "marketSegmentId": data.marketSegmentId,
             "buySell": data.buySell,
             "productType": data.productType,
             "orderType": data?.orderType,
             "quantity": data.quantity,
             "price": data.price,
             "disclosedQuantity": 0,
             "validity": data.validity,
             "isAmo": data.isAmo,
             "stockData": {
                 "lotSize": data?.stockData?.lotSize ?? 1,
                 "optionType": data?.stockData?.optionType ?? ""
               },
         };
     }
 
     function callTotalMarginApi(orders) {
         let data = orders.map(d => formateMarginDara(d))
         flowBackEndServices2.getTotalMargin({orders: data},user.access_token).then(value => {
             if(value.status === 200 && value.data.status === 'success'){
                dispatch(setPublicBasketMargin(value.data))
                setTotalMargin(value.data)
             }
         })
     }

      const fetchData = async (basket_id) => {
        flowBackEndService.getSharedBasket(basket_id,user.access_token)
        .then(value => {
            return value.data
        })
        .then(value=>{
            if(value.status==="success"){
            setApiDetail(value.response);
            dispatch(setBasketOldIdData(value.response.basketDetails))
          }else{
            toast.error("Shared basket expired or doesn't exist")
            sessionStorage.removeItem("public_basket_id")
            let state =  {id: 1,param: {fetch_new: true},url:'BasketFetch'};
            dispatch(setCurrentBasketState(state))
            dispatch(OpenPublicBasket(false))
          }
          setIsLoading(false)
        })
        .catch(error => {
            toast.error("Shared basket expired or doesn't exist");
            setIsLoading(false)
        })
      };

      const tableBody = ()=>{
        return(
            apiDetail.basketDetails.basketOrders.map((item,index) => {
                const id1 = nextId("BE-");
                return(
                    <PublicEachStock key ={index} data={item}/>
                )
            })
        )
      }

      
      const formatExpiry = (date) =>{
        const formattedDate = moment(date, 'YYYYMMDD').format('MMM DD, YYYY');
        return formattedDate
      }

      const saveBasket=(basketName,orders,execute=false)=>{
        dispatch(sharedBasketSave())
        callCreateBasket(basketName,orders,execute)
      }

      const callCreateBasket=(name,ordersList,execute)=>{
        const data={basketName: name,orders:ordersList}
        flowBackEndService.SaveBasket(data,user.access_token)
            .then(value => {
                  if(value.data.status=="success"){
                    toast.success("basket saved successfully!!!")
                    if(execute){
                      dispatch(ExecutePublicBasket(true))
                    }
                    let state =  {id: 1,param: {fetch_new: true},url:'BasketFetch'};
                    dispatch(setCurrentBasketState(state))
                    setSavedBasketId(value.data.response.id)
                    const updatedObject = {
                        ...apiDetail.basketDetails,
                        id: value.data.response.id,
                      };
                    dispatch(setPublicBasketData(updatedObject))
                  }else{
                    toast.error(`something went wrong`)
                  }
            })
            .catch(error => {
                toast.error(`${error.response.data.message},delete some existing basket`)
                // setIsLoading(false);
            })
            .finally(() => {
                // setIsLoading(false);
            })
    }

    const executeBasket = (basketName,orders) =>{
        saveBasket(basketName,orders,true)
    }

    const analyseGraph = () =>{
        let optionAvailable = false
        apiDetail.basketDetails.basketOrders.map((d)=>{
            if(d.stockData.marketSegmentId==2){
                optionAvailable = true
            }
        })
        if(optionAvailable){
            let state =  {id: 5,param: apiDetail.basketDetails,url:'publicBasketAnalyse'};
            dispatch(setCurrentBasketState(state))
            dispatch(sharedBasketAnalyse())
        }else{
            toast.error("At least one order of any option should be in the basket")
        }
    }

    const basketDetails = ()=>{
      return(
        <div className="d-flex flex-column justify-content-start align-items-start m-4">
            {apiDetail && <div className='pr-2 validity'>Valid till {formatExpiry(apiDetail.basketExpiryDate)}</div>}
        <div className="d-flex scroll_box3 my_scroll_track flex-column align-items-stretch w-100 justify-content-between">
            <table className="pubBasketTable">
                <thead className="card_bg">
                <tr className="headTxt">
                    <th>Name</th>
                    <th>LTP</th>
                    <th>Price</th>
                    <th>Qty.</th>
                </tr>
                </thead>
                <tbody>
                  {apiDetail && tableBody()}
                </tbody>

            </table>
        </div>
    </div>
      )
    }
    return (
        <React.Fragment>
                <BasketketHeader>
                    {apiDetail && <span className="heading2">{apiDetail.basketDetails.basketName}&nbsp;({apiDetail.basketDetails.basketOrders.length}/10)</span>}
                    <span onClick={()=>{saveBasket(apiDetail.basketDetails.basketName,apiDetail.basketDetails.basketOrders)}} className="save_button"><Save />&nbsp;<span className="save">Save</span></span>
                    {apiDetail && <span className='pl-2'>
                    <span className='tooltip2' style={{"position":"relative"}}>
                        <span className='c-pointer'>{<InfoCircle />}</span>
                            <div className="tooltiptext px-4 py-3" style={{"textAlign":"left"}}>
                                <div>1. Basket will be valid for 30 days from the day its shared. In case any scrip in the basket expires within 30 days, the basket link will also expire.</div>
                                <div className='py-2'>2. The link can be shared multiple times to multiple people from your end and receiver's end also.</div>
                            </div>
                        </span>
                    </span>}
                </BasketketHeader>
                {isLoading && (
                <CustomSpinner />
            )}
                {basketDetails()}
                <div className="bottom_execute_bar currentBg2">
                    <div onClick={()=>executeBasket(apiDetail.basketDetails.basketName,apiDetail.basketDetails.basketOrders)} className="execute_btn my-3 w-25 mr-4" variant="primary" type="submit">
                        Save & Execute
                    </div>
                    <div onClick={analyseGraph} style={{"marginLeft":"170px"}} className="analyse_btn my-3 w-25" variant="primary" type="submit">
                        Analyse
                    </div>
                    <div className='d-flex flex-row'>
                      <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                          <div className="text_grey currentText">Initial Margin</div>
                          <div className="heading2">{totalMargin?parseFloat(totalMargin.initialMargin).toFixed(2):0}</div>
                      </div>
                      <div className="d-flex ml-4 flex-column justify-content-evenly align-items-left">
                        <div className="text_grey currentText">Final Margin</div>
                        <div className="heading2">{totalMargin?parseFloat(totalMargin.requiredMargin).toFixed(2):0}</div>
                      </div>
                    </div>

                    </div>
    
    
            </React.Fragment>
    )
}

export default SharedBasketDetails